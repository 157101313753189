<template>
  <CContainer>
    <h1>
      Bienvenido a la aplicación
    </h1>
    <CRow>
      <CCol
        sm="4"
        class="componentes"
      >
        <CCard class="h-100">
          <CCardImage
            component="svg"
            orientation="top"
            class="docs-placeholder-img"
            preserve-aspect-ratio="xMidYMid slice"
            focusable="false"
          >
            <router-link
              to="/user"
              class="svg-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-people"
                viewBox="0 0 16 16"
              >
                <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
              </svg>
            </router-link>
          </CCardImage>
          <CCardBody>
            <CCardTitle>Usuarios</CCardTitle>
            <CCardtext>Página para la adminstración y gestión de los usuarios de la aplicación.</CCardtext>
          </CCardBody>
          <CButton
            to="/user"
            color="primary"
          >
            Ir a Usuarios
          </CButton>
        </CCard>
      </CCol>

      <CCol
        sm="4"
        class="componentes"
      >
        <CCard class="h-100">
          <CCardImage
            component="svg"
            orientation="top"
            class="docs-placeholder-img"
            role="img"
            aria-label="Placeholder: Image cap"
            preserve-aspect-ratio="xMidYMid slice"
            focusable="false"
          >
            <router-link
              to="/video"
              class="svg-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-people"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556v4.35zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H2z"
                />
              </svg>
            </router-link>
          </CCardImage>
          <CCardBody>
            <CCardTitle>Vídeos</CCardTitle>
            <CCardtext>Página para la adminstración y gestión de los vídeos de la aplicación.</CCardtext>
          </CCardBody>
          <CButton
            to="/video"
            color="primary"
          >
            Ir a Vídeos
          </CButton>
        </CCard>
      </CCol>

      <CCol
        sm="4"
        class="componentes"
      >
        <CCard class="h-100">
          <CCardImage
            component="svg"
            orientation="top"
            class="docs-placeholder-img"
            role="img"
            aria-label="Placeholder: Image cap"
            preserve-aspect-ratio="xMidYMid slice"
            focusable="false"
          >
            <router-link
              to="/consejo"
              class="svg-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-people"
                viewBox="0 0 16 16"
              >
                <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
              </svg>
            </router-link>
          </CCardImage>
          <CCardBody>
            <CCardTitle>Consejos</CCardTitle>
            <CCardtext>Página para la adminstración y gestión de los consejos de la aplicación.</CCardtext>
          </CCardBody>
          <CButton
            to="/consejo"
            color="primary"
          >
            Ir a Consejos
          </CButton>
        </CCard>
      </CCol>

      <CCol
        sm="4"
        class="componentes"
      >
        <CCard class="h-100">
          <CCardImage
            component="svg"
            orientation="top"
            class="docs-placeholder-img"
            role="img"
            aria-label="Placeholder: Image cap"
            preserve-aspect-ratio="xMidYMid slice"
            focusable="false"
          >
            <router-link
              to="/cuestionario"
              class="svg-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-people"
                viewBox="0 0 16 16"
              >
                <path d="M8.05 9.6c.336 0 .504-.24.554-.627.04-.534.198-.815.847-1.26.673-.475 1.049-1.09 1.049-1.986 0-1.325-.92-2.227-2.262-2.227-1.02 0-1.792.492-2.1 1.29A1.71 1.71 0 0 0 6 5.48c0 .393.203.64.545.64.272 0 .455-.147.564-.51.158-.592.525-.915 1.074-.915.61 0 1.03.446 1.03 1.084 0 .563-.208.885-.822 1.325-.619.433-.926.914-.926 1.64v.111c0 .428.208.745.585.745z" />
                <path d="m10.273 2.513-.921-.944.715-.698.622.637.89-.011a2.89 2.89 0 0 1 2.924 2.924l-.01.89.636.622a2.89 2.89 0 0 1 0 4.134l-.637.622.011.89a2.89 2.89 0 0 1-2.924 2.924l-.89-.01-.622.636a2.89 2.89 0 0 1-4.134 0l-.622-.637-.89.011a2.89 2.89 0 0 1-2.924-2.924l.01-.89-.636-.622a2.89 2.89 0 0 1 0-4.134l.637-.622-.011-.89a2.89 2.89 0 0 1 2.924-2.924l.89.01.622-.636a2.89 2.89 0 0 1 4.134 0l-.715.698a1.89 1.89 0 0 0-2.704 0l-.92.944-1.32-.016a1.89 1.89 0 0 0-1.911 1.912l.016 1.318-.944.921a1.89 1.89 0 0 0 0 2.704l.944.92-.016 1.32a1.89 1.89 0 0 0 1.912 1.911l1.318-.016.921.944a1.89 1.89 0 0 0 2.704 0l.92-.944 1.32.016a1.89 1.89 0 0 0 1.911-1.912l-.016-1.318.944-.921a1.89 1.89 0 0 0 0-2.704l-.944-.92.016-1.32a1.89 1.89 0 0 0-1.912-1.911l-1.318.016z" />
                <path d="M7.001 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" />
              </svg>
            </router-link>
          </CCardImage>
          <CCardBody>
            <CCardTitle>Cuestionarios</CCardTitle>
            <CCardtext>Página para la adminstración y gestión de los cuestionarios de la aplicación.</CCardtext>
          </CCardBody>
          <CButton
            to="/cuestionario"
            color="primary"
          >
            Ir a Cuestionarios
          </CButton>
        </CCard>
      </CCol>

      <CCol
        sm="4"
        class="componentes"
      >
        <CCard class="h-100">
          <CCardImage
            component="svg"
            orientation="top"
            class="docs-placeholder-img"
            role="img"
            aria-label="Placeholder: Image cap"
            preserve-aspect-ratio="xMidYMid slice"
            focusable="false"
          >
            <router-link
              to="/premio"
              class="svg-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-people"
                viewBox="0 0 16 16"
              >
                <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z" />
                <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z" />
              </svg>
            </router-link>
          </CCardImage>
          <CCardBody>
            <CCardTitle>Premios</CCardTitle>
            <CCardtext>Página para la adminstración y gestión de los premios de la aplicación.</CCardtext>
          </CCardBody>
          <CButton
            to="/premio"
            color="primary"
          >
            Ir a Premios
          </CButton>
        </CCard>
      </CCol>

      <CCol
        sm="4"
        class="componentes"
      >
        <CCard class="h-100">
          <CCardImage
            component="svg"
            orientation="top"
            class="docs-placeholder-img"
            role="img"
            aria-label="Placeholder: Image cap"
            preserve-aspect-ratio="xMidYMid slice"
            focusable="false"
          >
            <router-link
              to="/categoria"
              class="svg-link"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-people"
                viewBox="0 0 16 16"
              >
                <path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z" />
                <path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z" />
              </svg>
            </router-link>
          </CCardImage>
          <CCardBody>
            <CCardTitle>Categorías</CCardTitle>
            <CCardtext>Página para la adminstración y gestión de las categorías de la aplicación.</CCardtext>
          </CCardBody>
          <CButton
            to="/categoria"
            color="primary"
          >
            Ir a Categorías
          </CButton>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<style scoped>
  svg {
    display: block;
    margin: 0 auto;
    padding: 10px 10px;
    transition: transform .2s;
    width: 100%;
    height: 180px;
  }

  svg:hover {
    transform: scale(1.1);
  }

  .svg-link {
    color: inherit;
  }

  CCardImage {
    width: 100%;
    height:180;
  }

  .componentes {
    margin-bottom: 10px;
  }
</style>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";

/**
 * Función para comprobar el token de autenticacion.
 * En caso de ser incorrecto lo elimina del almacenamiento
 * interno y le vuelve a pedir las credenciales al usuario
 * 
 * @param {number} token - El token a comprobar
 */
async function checkToken(token) {
  await axios
    .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CHECKTOKEN, {
      headers: {
        Authorization: token,
      },
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location.href = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
}

export default {
  name: "Inicio",
  created() {
    checkToken(localStorage.token);
  },
};
</script>
